.dark-style {
  .logo {
    img {
      filter: invert(1);
    }
  }
  .mobile-menu {
    background: #17192b;
  }
  .mobile-menu .nav-item .nav-link::before {
    background: #fff;
  }
}

.dark-style .theme-menu-five .right-widget .demo-button img {
  filter: invert(1);
}
.css-111oz6r {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
