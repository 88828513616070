/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */

@font-face {
  font-family: "gordita";
  src: url("gordita_medium-webfont.woff2") format("woff2"),
    url("gordita_medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "gordita";
  src: url("gordita_regular-webfont.woff2") format("woff2"),
    url("gordita_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
